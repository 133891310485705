<template>
  <div class="test_sty">
    <div class="test_sty_box">
      <img src="@/assets/img/test/jiaojuanchenggong.png" alt />
      <div class="test_sty_text">你有一份新的试卷,请注意查看</div>
    </div>
    <div class="test_sty_but">
      <button class="test_sty_but_one" @click="cancelMassge">取消</button>
      <button class="test_sty_but_two" @click="goNowMassge">立即前往</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "testNotice",
  data() {
      return {

      }
  },
  methods: {
    //取消
    cancelMassge() {
      this.$emit("cancelMassge");
    },
    //立即前往
    goNowMassge() {
      this.$emit("cancelMassge");
      this.$router.push('/myStudy?option=4&path=message')
    },
  },
};
</script>

<style lang="less" scoped>
.test_sty {
  width: 100%;
  .test_sty_box {
    padding-top: 16px;
    width: 213px;
    margin: auto;
    display: block;
    & > img {
      width: 213px;
      height: 151px;
    }
    .test_sty_text {
      margin-top: 30px;
      font-size: 16px;
      font-weight: 500;
      color: #333333;
      line-height: 16px;
    }
  }
  .test_sty_but {
    margin-top: 44px;
    padding: 0 17px;
    width: 100%;
    height: 40px;
    & > button {
      width: 150px;
      height: 40px;
      border-radius: 20px;
      font-size: 14px;
      font-weight: 500;
      line-height: 40px;
      text-align: center;
    }
    .test_sty_but_one {
      background: #ffffff;
      color: #666666;
      border: 1px solid #e7e7e7;
      float: left;
    }
    .test_sty_but_two {
      background: #0d79ff;
      color: #ffffff;
      float: left;
      margin-left: 26px;
    }
  }
}
</style>