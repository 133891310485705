<template>
  <el-dialog
    class="interest_popup"
    title="选择感兴趣的课程"
    :visible.sync="show"
    width="929px"
    :before-close="handleClose"
  >
    <div class="top">
      <div class="top_category">
        <span
          class="tag"
          :class="{ active: current.categoryId == tag.categoryId }"
          v-for="tag in categorys"
          :key="tag.categoryId"
          @click="handleSel(tag)"
          >{{ tag.name }}</span
        >
      </div>
      <div class="secondary_category">
        <span
          class="tag"
          :class="{ active: isExistence(selCategorys, tag) }"
          v-for="tag in current.children"
          :key="tag.categoryId"
          @click="handleChoose(tag)"
          >{{ tag.name }}</span
        >
      </div>
    </div>
    <div class="bottom">
      <div class="tips">
        <span class="al">已选{{ selCategorys.length }}个：</span>
        <span class="ti">至少选择一个，以便更好为你推荐</span>
      </div>
      <div class="selected_tags">
        <span class="tag" v-for="tag in selCategorys" :key="tag.categoryId"
          >{{ tag.name
          }}<i class="iconfont" @click="handleDel(tag)">&#xe627;</i></span
        >
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <button
        class="btn"
        :class="{ disabled: selCategorys.length <= 0 }"
        type="primary"
        @click="handleComfirm"
      >
        选好了
      </button>
    </span>
  </el-dialog>
</template>

<script>
import { getCategorys } from "@/api/index";
import { buildTree } from "@/utils/treeShape";

export default {
  name: "interestPopup",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.getCategorys();
  },
  methods: {
    // 获取分类信息
    getCategorys() {
      getCategorys().then((res) => {
        if (res.code == 200) {
          this.categorys = buildTree(res.data);
        } else {
          console.log(res.msg);
        }
      });
    },
    handleClose() {
      this.$emit("close");
    },
    // 选好了
    handleComfirm() {
      if (this.selCategorys.length <= 0 ) return;
      let ids = [];
      this.selCategorys.forEach((item) => {
        ids.push(item.categoryId);
      });
      this.$emit("close", ids);
    },
    // 选择一级分类
    handleSel(ctx) {
      this.current = ctx;
    },
    // 是否存在
    isExistence(arr, i) {
      return arr.some((e) => {
        return e.categoryId == i.categoryId;
      });
    },
    // 选择标签
    handleChoose(i) {
      if (this.isExistence(this.selCategorys, i)) {
        let len = this.selCategorys.indexOf(i);
        this.selCategorys.splice(len, 1);
      } else {
        this.selCategorys.push(i);
      }
    },
    // 删除标签
    handleDel(ctx) {
      let len = this.selCategorys.indexOf(ctx);
      this.selCategorys.splice(len, 1);
    },
  },
  data() {
    return {
      buildTree,
      categorys: [],
      current: {},
      selCategorys: [],
    };
  },
};
</script>

<style lang="less" scoped>
.interest_popup {
  ::v-deep .el-dialog {
    border-radius: 6px;
    overflow: hidden;
    .el-dialog__header {
      padding: 17px 0;
      background-color: #f6f6f6;
      .el-dialog__title {
        color: #333;
        font-size: 20px;
        font-weight: bold;
      }
    }
    .el-dialog__body {
      padding: 23px 26px 40px;
      .top {
        .top_category {
          margin-bottom: 10px;
          width: 100%;
          white-space: nowrap;
          text-align: left;
          overflow-x: auto;
          .tag {
            display: inline-block;
            margin-bottom: 15px;
            padding: 7px 20px;
            border-radius: 20px;
            cursor: pointer;
            transition: color 0.3s ease;
            &:hover {
              color: #0d79ff;
            }
            &.active {
              color: #fff;
              background-color: #0d79ff;
            }
          }
        }
        .secondary_category {
          margin-bottom: 15px;
          width: 100%;
          text-align: left;
          .tag {
            display: inline-block;
            margin-bottom: 15px;
            margin-right: 24px;
            padding: 7px 20px;
            border: solid 1px #e6e6e6;
            border-radius: 20px;
            cursor: pointer;
            transition: color 0.3s ease;
            &:hover {
              color: #0d79ff;
            }
            &.active {
              color: #0d79ff;
              border-color: #0d79ff;
            }
          }
        }
      }
      .bottom {
        border-top: solid 1px #eaeaea;
        .tips {
          margin: 13px 0 20px;
          text-align: left;
          .al {
            margin-right: 28px;
            color: #666;
            font-size: 14px;
          }
          .ti {
            color: #999;
            font-size: 12px;
          }
        }
        .selected_tags {
          text-align: left;
          .tag {
            display: inline-block;
            margin-right: 24px;
            margin-bottom: 15px;
            padding: 7px 10px 7px 20px;
            border: solid 1px #e6e6e6;
            border-radius: 20px;
            .iconfont {
              margin-left: 5px;
              vertical-align: middle;
              cursor: pointer;
            }
          }
        }
      }
    }
    .el-dialog__footer {
      padding-bottom: 34px;
      text-align: center;
      .btn {
        width: 228px;
        height: 44px;
        font-size: 16px;
        background-color: #0d79ff;
        color: #fff;
        border-radius: 22px;
      }
    }
  }
}
</style>