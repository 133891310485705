<template>
  <div class="container">
    <!-- 轮播 -->
    <div class="banner">
      <el-carousel height="366px" v-loading="isLoading">
        <el-carousel-item v-for="item in bannerList" :key="item.bannerId">
          <img
            class="banner_item"
            :src="item.img"
            alt="图片"
            @click="rotationImg(item.pcLink)"
          />
        </el-carousel-item>
      </el-carousel>
    </div>

    <!-- 猜你喜欢 -->
    <div class="guess_like">
      <p class="section_title">猜你喜欢</p>
      <div>
        <one-lesson
          class="one_lesson"
          v-for="lesson in gussLikeList"
          :key="lesson.courseId"
          :lessonInfo="lesson"
          @click.native="goDetail(lesson.courseId)"
        />
      </div>
    </div>
    <!-- <div class="guess_like" v-if="schoolCourseList.length > 0">
      <p class="section_title">院校课程</p>
      <div>
        <one-lesson
          class="one_lesson"
          v-for="lesson in schoolCourseList"
          :key="lesson.courseId"
          :lessonInfo="lesson"
          :showBottom="false"
          @click.native="goDetail(lesson.courseId)"
        />
      </div>
    </div> -->

    <!-- 热门课程 -->
    <!-- <div class="hot_lesson" v-for="item in 2" :key="item">
      <div style="position: relative">
        <p class="section_title">热门课程</p>
        <span class="more_lesson">更多<i class="iconfont">&#xe614;</i></span>
      </div>
      <div>
        <one-lesson
          class="one_lesson"
          v-for="lesson in hotList"
          :key="lesson.courseId"
          :lessonInfo="lesson"
          width="282"
          height="174"
          @click.native="goDetail(lesson.courseId)"
        />
      </div>
    </div> -->

    <!-- 兴趣选择弹窗 -->
    <interest-popup :show="chooseInsterest" @close="handleClose" />
    <!-- 考试通知弹窗 -->
    <div class="test_message_show">
      <el-dialog :visible.sync="newskShow" width="400px">
        <test-notice
          @cancelMassge="cancelMassge"
          @goNowMassge="goNowMassge"
        ></test-notice>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import oneLesson from "@/components/oneLesson/index";
import interestPopup from "@/components/interestPopup/index";
import { getBanner, addInterst, getInterst } from "@/api/index";
// import { getUserInfo } from "@/api/personal";
import { getMessageList } from "@/api/notice";
import testNotice from "@/components/myMessage/testNotice";
// import { getCourses } from "@/api/curriculum";
export default {
  name: "Home",
  components: {
    oneLesson,
    interestPopup,
    testNotice,
  },
  computed: {
    userInfo() {
      return this.$store.state.personalInfo;
    },
  },
  created() {
    this.getBannerList();
    this.getInterstList();
    // this.getSchoolCourseList()
    if (this.userInfo.memberId) {
      this.getMessageList();
    }
    this.chooseInsterest = (this.userInfo.memberId && !this.userInfo.favorite) ? true : false;
  },
  beforeDestroy() {
    this.bannerList = [];
  },
  methods: {
    // getSchoolCourseList() {
    //   getCourses({open: 2}).then((res) => {
    //     if (res.code == 200) {
    //       this.schoolCourseList = res.rows;
    //     } else {
    //      this.$message.error(res.msg);
    //     }
    //   });
    // },
    // 获取轮播图
    getBannerList() {
      this.$loading.show();
      getBanner(this.bannerParams).then((res) => {
        this.$loading.hide();
        if (res.code == 200) {
          this.bannerList = res.data;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 获取猜你喜欢
    getInterstList() {
      getInterst(this.interstParams).then((res) => {
        if (res.code == 200) {
          this.gussLikeList = res.rows;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 跳转课程详情页
    goDetail(courseId) {
      let newUrl = this.$router.resolve({
        path: "/courseDetail",
        query: { courseId },
      });
      window.open(newUrl.href, "_blank");
    },
    // 关闭兴趣弹窗
    handleClose(val) {
      this.chooseInsterest = false;
      if (val) {
        // localStorage.setItem("recommends", JSON.stringify(val));
        addInterst({ favorite: val }).then((res) => {
          if (res.code == 200) {
            this.$message.success("添加成功！");
            this.getInterstList();
          } else {
            this.$message.error(res.msg);
          }
        });
      }
    },
    //获取考试信息
    getMessageList() {
      let that = this;
      let params = { type: "test", read: 0 };
      getMessageList(params).then((res) => {
        if (res.code == 200) {
          let noticeIds = res.rows.map((item) => item.noticeId);
          let localIds = JSON.parse(localStorage.getItem("noticeArr")) || [];
          const noticeFlag = noticeIds.some(
            (item) => !localIds.find((id) => id == item)
          );
          if (noticeFlag) {
            that.newskShow = true;
            localStorage.setItem("noticeArr", JSON.stringify(noticeIds));
          }
        } else {
          that.newskShow = false;
        }
        console.log(that.newskShow);
      });
    },
    //关闭消息弹窗
    cancelMassge() {
      this.newskShow = false;
    },
    //立即前往
    goNowMassge() {
      this.newskShow = false;
    },
    //轮播图跳转
    rotationImg(url) {
      if (url) {
        this.$router.push(url);
      }
    },
  },
  data() {
    return {
      newskShow: false, //考试消息弹窗
      bannerParams: {
        type: "default",
        channel: "pc",
      },
      bannerList: [],
      interstParams: {
        pageSize: 25,
      },
      isLoading: false,
      gussLikeList: [],
      chooseInsterest: false,
      schoolCourseList: []
    };
  },
};
</script>

<style lang="less" scoped>
.banner {
  margin-top: 36px;
  .banner_item {
    width: 100%;
    height: 100%;
  }
}
.section_title {
  font-size: 24px;
  text-align: left;
  line-height: 3;
}
.more_lesson {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}
.guess_like {
  margin-top: 16px;
  margin-bottom: 50px;
  text-align: left;
  .one_lesson {
    margin-right: 22px;
    margin-bottom: 22px;
    &:nth-child(5n) {
      margin: 0;
    }
  }
}
.hot_lesson {
  margin-top: 16px;
  text-align: left;
  .one_lesson {
    margin-right: 22px;
    margin-bottom: 25px;
    &:nth-child(4n) {
      margin: 0;
    }
  }
}

//考试通知
.test_message_show {
  /deep/.el-dialog {
    border-radius: 6px;
  }
}
</style>