<template>
  <div style="height: 66px">
    <div class="top_nav_content_sty">
      <div class="hear_nav_sty">
        <div class="main_content_sty clearfix">
          <div class="indong_logo_sty" @click="$router.push({name: 'home'})">
            <!-- <img style="width: 155px" src="@/assets/img/logo/logo.png" /> -->
          </div>
          <div
            class="hear_tab_sty"
            v-for="(i, index) in tabList"
            :key="i.id"
            :class="[{ checked_sty: checked == i.name }, `nav_sty${index}`]"
            @click="changeTab(i.name)"
          >
            {{ i.text }}
            <div class="classification_middle_sty"></div>
            <div class="classification_sty" @click.stop>
              <div class="classification_content_sty">
                <div
                  class="classification_first_sty"
                  v-for="c in classificationList"
                  :key="c.categoryId"
                  @mouseover="selectCategory(c.categoryId)"
                  @click="goDetail()"
                  :class="[
                    { first_checked_sty: c.categoryId === categoryFirstId },
                  ]"
                >
                  {{ c.name }}
                </div>
              </div>
              <div class="classification_second_content_sty">
                <div
                  class="classification_second_sty"
                  v-for="s in secondClassificationList"
                  :key="s.categoryId"
                >
                  <div @click="goDetail(s.categoryId)">
                    {{ s.name }}
                  </div>
                  <div
                    v-for="o in s.children"
                    :key="o.categoryId"
                    class="classification_third_sty"
                    @click="goDetail(s.categoryId, o.categoryId)"
                  >
                    {{ o.name }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="right_content_sty">
            <div class="search_content_sty" :class="[{ input_sty: isInput }]">
              <input
                type="text"
                placeholder="搜索你要学习的课程"
                @blur="isInput = false"
                @focus="isInput = true"
                v-model="searchText"
                @keyup.enter="search"
              />
              <i class="iconfont search_icon_sty" @mousedown.stop="search"
                >&#xe611;</i
              >
              <div
                class="history_content_sty"
                v-show="searchHistory.length !== 0 && isInput"
              >
                <div class="title_sty">历史搜索</div>
                <ul>
                  <li
                    v-for="(i, index) in searchHistory"
                    :key="index"
                    @mousedown.stop="historySearch(i)"
                  >
                    {{ i }}
                  </li>
                </ul>
              </div>
            </div>
            <div class="notice_content_sty" v-if="isLogin">
              <i class="iconfont" :class="newsMessag == 1 ? '' : 'hasNewNotice'"
                >&#xe62d;</i
              >
              <div class="center_content_sty"></div>
              <div class="notice_content_list_sty">
                <div class="notice_center_content_sty" v-if="!emptyMessage">
                  <ul>
                    <li
                      v-for="(i, index) in noticeList"
                      :key="i.noticeId"
                      @click="goNoticeDetail(i.noticeId)"
                    >
                      <div class="notice_icon_sty">
                        <svg class="icon" aria-hidden="true">
                          <use
                            :xlink:href="
                              i.type == 'common'
                                ? '#iconweibiaoti-1_huaban11'
                                : '#iconweibiaoti-1_huaban1fuben1'
                            "
                          ></use>
                        </svg>
                        <span v-if="i.read == 0"></span>
                      </div>
                      <div class="notice_main_content_sty">
                        <div>
                          <h3>{{ i.type === "test" ? "考试" : "系统" }}通知</h3>
                          <p>{{ formatDate(i.createTime, "MM-DD") }}</p>
                        </div>
                        <div class="notice_text_sty">
                          {{
                            i.type === "test"
                              ? "您有一份新的试卷"
                              : "您有一条新的消息"
                          }}
                        </div>
                        <div
                          class="notice_line_sty"
                          v-if="noticeList.length != index + 1"
                        ></div>
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="notice_center_content_empty" v-else>
                  <empty-status
                    :img="require('@/assets/img/emptyIcon/wuxiaoxi@2x.png')"
                    :desc="'没有新消息'"
                  />
                </div>
                <div
                  class="see_more_sty"
                  v-if="noticeList.length == 4"
                  @click="seeMore()"
                >
                  查看更多
                </div>
              </div>
            </div>
            <div class="header_content_sty" v-if="isLogin">
              <div>
                <img :src="headerImg" alt="头像" />
              </div>
              <div class="header_middle_sty"></div>
              <div class="header_block_sty">
                <ul>
                  <li @click="goAccountSet">
                    <i class="iconfont">&#xe603;</i>账号设置
                  </li>
                  <li @click="logout">
                    <i class="iconfont">&#xe609;</i>退出登录
                  </li>
                </ul>
              </div>
            </div>
            <router-link to="/login" tag="div" class="login_btn_sty" v-else
              >登录/注册</router-link
            >
          </div>
        </div>
      </div>
    </div>
    <!-- 右侧菜单 -->
    <div class="menu" :style="{ left: leftDistance }">
      <ul>
        <li
          v-for="(item, index) in fixedMenu"
          :key="index"
          @click="fixedMenuBut(index)"
        >
          <i class="iconfont" :class="item.ico"></i>
          <div class="join_class">{{ item.text }}</div>
          <div class="menu_line"></div>
          <div class="menu_code">
            <img src="@/assets/img/qrcode_for_gh_5c8229908475_258.jpg" />
          </div>
        </li>
        <li>
          <a
            target="_blank"
            href="http://wpa.qq.com/msgrd?v=3&uin=1785382368&site=qq&menu=yes"
            ><img
              border="0"
              src="http://wpa.qq.com/pa?p=2:594040367:51"
              alt="点击这里给我发消息"
              title="点击这里给我发消息"
          /></a>
        </li>
      </ul>
      <div class="menu_div" v-if="returnTop" @click="returnTopBut">
        <i class="iconfont">&#xe616;</i>
      </div>
    </div>
    <el-dialog :visible.sync="dialogVisible" width="400px">
      <add-class @close="close" :isClean="dialogVisible" />
    </el-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { getNoticeList, getMessageList } from "@/api/notice";
import { formatDate } from "@/utils/index";
import addClass from "../addClass/index";
import emptyStatus from "@/components/emptyStatus/index";
export default {
  components: {
    addClass,
    emptyStatus,
  },
  data() {
    return {
      categoryFirstId: null,
      isInput: false,
      searchText: "",
      searchHistory: [],
      noticeList: [],
      formatDate,
      fixedMenu: [
        { ico: "iconweibiaoti-1-14", text: "加入班级" },
        { ico: "iconweibiaoti-1-12", text: "意见反馈" },
        { ico: "iconweibiaoti-1-17", text: "微信公众号" },
      ], //右侧菜单
      returnTop: false, //返回顶部键
      dialogVisible: false, //加入班级
      leftDistance: 0,
      newsMessag: 1, //是否有新消息
      emptyMessage: false, //是否有消息
    };
  },
  computed: {
    checked() {
      return this.$route.name;
    },
    classificationList: function () {
      let list = this.$store.state.classification;
      return list;
    },
    secondClassificationList: function () {
      let list = [];
      if (this.$store.state.classification.length !== 0) {
        this.$store.state.classification.forEach((e) => {
          if (e.categoryId == this.categoryFirstId) {
            list = e.children ? e.children : [];
          }
        });
      }
      return list;
    },
    personalInfo: function () {
      return this.$store.state.personalInfo;
    },
    isLogin: function () {
      let list = Object.keys(this.$store.state.personalInfo);
      if (list.length === 0) {
        return false;
      } else {
        return true;
      }
    },
    headerImg: function () {
      let list = Object.keys(this.$store.state.personalInfo);
      if (list.length != 0 && !this.$store.state.personalInfo.icon) {
        return require("@/assets/img/defaultavatar.png");
      } else {
        return this.$store.state.personalInfo.icon;
      }
    },
    tabList() {
      const list = [
        {
          id: "11",
          text: "首页",
          name: "home",
        },
        {
          id: "12",
          text: "全部课程",
          name: "allCourses",
        },
        {
          id: "13",
          text: "我的学习",
          name: "myStudy",
        },
        {
          id: "14",
          text: "人才招聘",
          name: "recruit",
        },
        {
          id: "15",
          text: "新闻资讯",
          name: "news",
        },
        {
          id: "16",
          text: "帮助中心",
          name: "helpCenter",
        },
      ];
      if (!this.isLogin) {
        list.splice(2, 1);
      }
      return list;
    },
  },
  watch: {
    classificationList: function () {
      this.categoryFirstId =
        this.classificationList.length !== 0
          ? this.classificationList[0].categoryId
          : null;
    },
    $route() {
      let token = localStorage.getItem("token") || "";
      if (token !== "") {
        this.getNoticeList();
        this.getMessageList();
      }
    },
  },
  created() {
    this.getCategorys();
    this.getLeftDistance();
    let token = localStorage.getItem("token") || "";
    if (token !== "") {
      this.getPersonalInfo();
      this.getNoticeList();
      this.getMessageList();
    }
  },
  mounted() {
    this.searchText = this.$route.query.title || "";
    this.searchHistory = JSON.parse(localStorage.getItem("historyList")) || [];

    //页面滚动事件
    window.addEventListener("scroll", () => {
      let top =
        document.documentElement.scrollTop ||
        document.body.scrollTop ||
        window.pageYOffset;
      if (top > 30) {
        this.returnTop = true;
      } else {
        this.returnTop = false;
      }
    });

    window.onresize = () => {
      return (() => {
        this.getLeftDistance();
      })();
    };
  },
  methods: {
    //是否有新消息
    getMessageList() {
      let that = this;
      let params = { read: 0 };
      getMessageList(params).then((res) => {
        if (res.code == 200) {
          if (res.rows.length != 0) {
            that.newsMessag = 2;
            return;
          }
        }
        that.newsMessag = 1;
      });
    },
    //返回顶部按钮
    returnTopBut() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },
    // 切换tab
    changeTab(name) {
      if (name == "myStudy") {
        this.$router.push("/myStudy?option=0&path=myLesson");
      } else if (name == "helpCenter") {
        this.$router.push("/helpCenter?option=0&path=index");
      } else {
        this.$router.push({ name: name });
      }
    },
    // 进详情页
    goDetail(st, tt) {
      let path = "/allCourses";
      if (this.categoryFirstId) {
        path += "?mt=" + this.categoryFirstId;
        if (st) {
          path += "&st=" + st;
          if (tt) {
            path += "&tt=" + tt;
          }
        }
      }

      this.$router.push({
        path,
      });
    },
    // 选择分类
    selectCategory(id) {
      this.categoryFirstId = id;
    },
    // 搜索
    search() {
      if (this.searchText.trim() !== "") {
        this.$router.push(`/allCourses?title=${this.searchText}`);
        this.savaHistory();
      } else {
        this.$router.push(`/allCourses`);
      }
    },
    // 历史记录搜索
    historySearch(val) {
      this.searchText = val;
      this.$router.push(`/allCourses?title=${this.searchText}`);
    },
    // 储存搜索历史
    savaHistory() {
      this.searchHistory.forEach((e, index) => {
        if (e === this.searchText) {
          this.searchHistory.splice(index, 1);
        }
      });
      this.searchHistory.unshift(this.searchText);
      if (this.searchHistory.length > 4) {
        this.searchHistory = this.searchHistory.slice(0, 4);
      }
      localStorage.setItem("historyList", JSON.stringify(this.searchHistory));
    },
    // 通知列表
    getNoticeList() {
      getNoticeList().then((json) => {
        const { code, rows, msg, total } = json;
        if (code === 200) {
          if (total <= 0) {
            this.emptyMessage = true;
          } else {
            this.emptyMessage = false;
          }
          if (rows.length > 4) {
            this.noticeList = rows.splice(0, 4);
          } else {
            this.noticeList = rows;
          }
        } else {
          this.$message({
            type: "error",
            message: msg,
          });
        }
      });
    },
    // 前往消息中心
    goNoticeDetail(id) {
      this.$router.push({
        path: "myStudy?option=4&path=messageDetails",
        query: { id: id },
      });
    },
    // 查看更多
    seeMore() {
      this.$router.push("myStudy?option=4&path=message");
    },
    // 登出
    logout() {
      localStorage.removeItem("token");
      this.$store.commit("CLEAR_PERSONALINFO");
      this.$router.push("/login");
    },
    //  账号设置
    goAccountSet() {
      this.$router.push("/myStudy?option=5&path=accountSettings");
    },
    ...mapActions(["getCategorys", "getPersonalInfo"]),

    //右边固定菜单栏
    fixedMenuBut(key) {
      if (key == 0) {
        if (this.dialogVisible) {
          this.dialogVisible = false;
        } else {
          this.dialogVisible = true;
        }
      } else if (key == 1) {
        this.$router.push("/helpCenter?option=1&path=feedback");
        this.close();
      } else {
        this.close();
      }
    },
    // 关闭弹出层
    close(val) {
      this.dialogVisible = val;
    },
    // 动态获取块的左间距
    getLeftDistance() {
      this.leftDistance = (document.body.clientWidth - 1200) / 2 + 1226 + "px";
    },
  },
};
</script>

<style lang="less" scoped>
.top_nav_content_sty {
  width: 100%;
  height: 66px;
  min-width: 1200px;
}
.hear_nav_sty {
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06);
  height: 66px;
  background: #fff;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 999;
}
.indong_logo_sty {
  float: left;
  padding-top: 13px;
  margin-right: 60px;
  cursor: pointer;
}
.hear_tab_sty {
  float: left;
  height: 66px;
  line-height: 66px;
  width: 82px;
  text-align: center;
  color: #333;
  cursor: pointer;
  &:hover {
    color: #0d79ff;
  }
}
.checked_sty {
  background: #0d79ff;
  color: #fff !important;
}
.right_content_sty {
  float: right;
  position: relative;
  .search_content_sty {
    display: inline-block;
    width: 239px;
    height: 34px;
    background: #f6f6f6;
    border: 1px solid #e8e8e8;
    border-radius: 17px;
    margin-top: 15px;
    position: relative;
    & > input {
      width: 100%;
      height: 100%;
      padding-left: 17px;
      font-size: 14px;
      width: 180px;
      overflow: hidden;
      &::placeholder {
        color: #999;
        font-size: 14px;
      }
    }
    & > .search_icon_sty {
      position: absolute;
      right: 16px;
      top: 10px;
      color: #999;
      cursor: pointer;
    }
  }
  .notice_content_sty {
    display: inline-block;
    margin-left: 58px;
    vertical-align: middle;
    position: relative;
    .center_content_sty {
      position: absolute;
      width: 80px;
      left: -50px;
      height: 22px;
      cursor: pointer;
      background: red;
      opacity: 0;
      display: none;
    }
    &:hover {
      color: #0d79ff;
      .notice_content_list_sty {
        display: block;
      }
      .center_content_sty {
        display: block;
      }
    }
    & > i {
      font-size: 21px;
      cursor: pointer;
    }
    .notice_content_list_sty {
      width: 300px;
      vertical-align: top;
      display: none;
      background: #ffffff;
      box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
      border-radius: 4px;
      position: absolute;
      left: -270px;
      top: 45px;
      z-index: 99;
      & > .see_more_sty {
        height: 49px;
        line-height: 49px;
        text-align: center;
        font-size: 12px;
        color: #666;
        width: 100%;
        border-top: 1px solid #eeeeee;
        cursor: pointer;
        &:hover {
          background: #f6f6f6;
        }
      }
      .notice_center_content_sty {
        & > ul {
          & > li {
            &:hover {
              background: #f6f6f6;
            }
            height: 75px;
            width: 100%;
            padding: 18px 16px 0;
            box-sizing: border-box;
            cursor: pointer;
            & > .notice_icon_sty {
              width: 32px;
              height: 32px;
              display: inline-block;
              position: relative;
              & > .icon {
                width: 32px;
                height: 32px;
              }
              & > span {
                position: absolute;
                width: 8px;
                height: 8px;
                background: #ff3939;
                border-radius: 50%;
                border: 2px solid #ffffff;
                left: 25px;
                top: 3px;
              }
            }
            .notice_main_content_sty {
              display: inline-block;
              vertical-align: top;
              width: 227px;
              margin-left: 8px;
              margin-top: 3px;
              & > div {
                width: 100%;
                &:first-child {
                  position: relative;
                  line-height: 14px;
                  & > h3 {
                    font-size: 14px;
                    color: #333;
                  }
                  & > p {
                    position: absolute;
                    right: 0;
                    top: 0;
                    font-size: 12px;
                    color: #999;
                  }
                }
              }
              .notice_text_sty {
                font-size: 12px;
                color: #999;
                margin-top: 8px;
                line-height: 12px;
              }
              .notice_line_sty {
                width: 100%;
                height: 1px;
                background: #eeeeee;
                margin-top: 19px;
              }
            }
          }
        }
      }
      .notice_center_content_empty {
        width: 100%;
        height: 300px;
      }
    }
  }
  .header_content_sty {
    display: inline-block;
    vertical-align: middle;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    margin-left: 34px;
    cursor: pointer;
    position: relative;
    &:hover {
      .header_block_sty {
        display: block;
      }
      .header_middle_sty {
        display: block;
      }
    }
    .header_middle_sty {
      width: 40px;
      height: 18px;
      background: red;
      position: absolute;
      opacity: 0;
      display: none;
    }
    & > div:first-child {
      width: 36px;
      height: 36px;
      border-radius: 50%;
      overflow: hidden;
      & > img {
        width: 100%;
        height: 100%;
      }
    }
    .header_block_sty {
      position: absolute;
      width: 150px;
      right: 0;
      top: 53px;
      background: #fff;
      color: #333;
      display: none;
      box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
      border-radius: 4px;
      & > ul {
        & > li {
          padding: 20px 0;
          padding-left: 34px;
          font-size: 14px;
          color: #333;
          &:hover {
            background: #f6f6f6;
          }
          &:first-child {
            border-bottom: 1px solid #eaeaea;
          }
          i {
            display: inline-block;
            vertical-align: middle;
            margin-right: 4px;
          }
        }
      }
    }
  }
  .login_btn_sty {
    background: #0d79ff;
    color: #fff;
    border-radius: 40px;
    display: inline-block;
    font-size: 14px;
    height: 34px;
    line-height: 34px;
    padding: 0 15px;
    cursor: pointer;
    margin-left: 34px;
  }
}
.hasNewNotice {
  position: relative;
  &::after {
    position: absolute;
    right: 3px;
    width: 4px;
    height: 4px;
    background: #ff3939;
    border-radius: 50%;
    top: 2px;
    content: "";
  }
}
.nav_sty1:hover {
  position: relative;
  .classification_sty {
    display: block;
  }
  .classification_middle_sty {
    display: block;
  }
}

.classification_middle_sty {
  width: 82px;
  height: 18px;
  position: absolute;
  opacity: 0;
  display: none;
}

.classification_sty {
  position: absolute;
  color: #666;
  display: none;
  width: 644px;
  min-height: 402px;
  background: #ffffff;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  text-align: left;
  top: 68px;
  &::before {
    content: "";
    position: absolute;
    width: 1px;
    height: 100%;
    background: #eaeaea;
    left: 164px;
  }
}
.classification_content_sty {
  position: relative;
  float: left;
}
.classification_first_sty {
  width: 164px;
  height: 52px;
  line-height: 52px;
  padding-left: 28px;
  box-sizing: border-box;
  position: relative;
}
.first_checked_sty {
  background: #f6f6f6;
  color: #333 !important;
  &::before {
    position: absolute;
    content: "";
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 3px;
    height: 26px;
    border-radius: 0px 3px 3px 0px;
    background: #0d79ff;
  }
}
.classification_second_content_sty {
  float: left;
  line-height: 1;
  padding: 0 36px;
  box-sizing: border-box;
}
.classification_second_sty {
  margin: 22px 0;
  & > div:first-child {
    position: relative;
    &::before {
      position: absolute;
      content: "";
      left: -8px;
      width: 3px;
      height: 14px;
      background: #0d79ff;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .classification_third_sty {
    padding: 22px 0;
    &:hover {
      color: #0d79ff;
    }
  }
}
.input_sty {
  border: 1px solid #0d79ff !important;
}
.history_content_sty {
  position: absolute;
  width: 240px;
  max-height: 380px;
  background: #ffffff;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  left: 0;
  top: 52px;
  padding: 22px 0;
  box-sizing: border-box;
  & > .title_sty {
    font-size: 12px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #b3b3b3;
    margin-left: 22px;
  }
  & > ul {
    li {
      height: 38px;
      padding: 0 22px;
      line-height: 38px;
      box-sizing: border-box;
      cursor: pointer;
      &:hover {
        background: #f6f6f6;
      }
    }
  }
  .line_sty {
    width: 200px;
    height: 1px;
    background: #eeeeee;
    margin: 10px 0;
    margin-left: 22px;
  }
}
input:focus {
  border: none;
}

//右侧菜单
.menu {
  position: fixed;
  top: 369px;
  width: 90px;
  // height: 241px;
  background: #ffffff;
  border: 1px solid #dedede;
  z-index: 999;
  & > ul {
    // position: absolute;
    width: 100%;
    & > li {
      text-align: -webkit-center;
      height: 80px;
      padding-top: 16px;
      cursor: pointer;
      & > .iconfont {
        font-size: 25px;
      }
      & > .join_class {
        font-size: 14px;
        font-weight: 500;
        color: #666666;
        line-height: 14px;
        margin-top: 7px;
      }
      & > .menu_line {
        width: 60px;
        height: 1px;
        background: #eeeeee;
        margin-top: 18px;
      }
    }
    & > li:hover {
      color: #0d79ff;
      .join_class {
        color: #0d79ff;
      }
    }
    & > li:nth-child(3):hover {
      .menu_code {
        display: block;
      }
    }
    & > li:last-child {
      height: 50px;
    }
  }
  & > .menu_div {
    width: 88px;
    height: 80px;
    background: #ffffff;
    line-height: 80px;
    cursor: pointer;
    position: absolute;
    top: 298px;
    border: 1px solid #dedede;
    text-align: center;
    & > .iconfont {
      font-size: 25px;
    }
  }
  .menu_code {
    position: absolute;

    background: #fff;
    top: 116px;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
    left: -170px;
    display: none;
    > img {
      width: 160px;
      height: 160px;
    }
    &::after {
      position: absolute;
      content: "";
      width: 0;
      height: 0;
      border: 10px solid transparent;
      border-top-color: #fff;
      top: 80px;
      left: 99%;
      transform: rotate(270deg);
    }
  }
}
</style>