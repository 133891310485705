import Http from '@/utils/http'
const baseUrl = '/app/study'


/**
 * 获取消息列表
 * @param 
 * 
*/

export const getNoticeList = () => Http.get(`${baseUrl}/member/notice`)

/**
 * 通知列表
 * @param 
 * 
*/

export const getMessageList = params => Http.get(`${baseUrl}/member/notice`,{params})

/**
 * 通知详情
 * @param 
 * 
*/

export const getMessageDetails = noticeId => Http.get(`${baseUrl}/member/notice/${noticeId}`)