<template>
  <div class="bottom_content_sty">
    <div class="main_content_sty">
      <div class="main_content_top_sty">
        <div class="main_content_top_logo_sty">
          <!-- <img
            style="width: 155px"
            src="@/assets/img/logo/grayLogo.png"
            alt="logo图片"
          /> -->
        </div>
        <div class="main_center_content_sty">
          <div v-for="i in tabList" :key="i.id" @click="handleLink(i)">
            {{ i.text }}
          </div>
        </div>
        <div class="main_right_content_sty">
          <div>
            <i class="iconfont">&#xe60c;</i>
            <div class="wx_code_content_sty">
              <img
                src="@/assets/img/qrcode_for_gh_5c8229908475_258.jpg"
                alt=""
              />
            </div>
          </div>
          <!-- <div class="distance_sty"></div>
          <div>
            <i class="iconfont">&#xe610;</i>
            <div class="xcx_code_content_sty"></div>
          </div> -->
        </div>
      </div>
      <div class="main_content_bottom_sty">
        <!-- <p>
          厦门易普道信息科技有限公司
          地址：厦门市思明区软件园观日路8号305
        </p> -->
        <a target="_blank" href="https://beian.miit.gov.cn/"
          >闽ICP备2020021594号-3</a
        >
      </div>
    </div>
  </div>
</template>


<script>
export default {
  data() {
    return {
      tabList: [
        {
          id: "11",
          text: "首页",
          name: "home",
        },
        {
          id: "12",
          text: "全部课程",
          name: "allCourses",
        },
        {
          id: "13",
          text: "院校课程",
        },
        {
          id: "14",
          text: "帮助中心",
          name: "helpCenter",
        },
      ],
    };
  },
  methods: {
    handleLink(i) {
      if (i.id == "13") {
        window.open("http://s.ypdstudy.com/student", "_blank");
      } else {
        this.$router.push({ name: i.name });
      }
    },
  },
};
</script>


<style lang="less" scoped>
.bottom_content_sty {
  width: 100%;
  background: #2d2d2d;
  height: 200px;
  min-width: 1200px;
}
.main_content_sty {
  border-bottom: 1px solid #3a3a3a;
  height: 132px;
  & > .main_content_top_sty {
    height: 132px;
    .main_content_top_logo_sty {
      display: inline-block;
      vertical-align: top;
      margin-top: 62px;
    }
    .main_center_content_sty {
      display: inline-block;
      vertical-align: top;
      margin-top: 76px;
      & > div {
        color: #999;
        display: inline-block;
        cursor: pointer;
        transition: all 0.3s ease;
        &:hover {
          color: #eee;
        }
        &:first-child {
          margin-left: 230px;
          margin-right: 82px;
        }
        &:nth-child(2) {
          margin-right: 80px;
        }
        &:nth-child(3) {
          margin-right: 80px;
        }
      }
    }
    .main_right_content_sty {
      float: right;
      .distance_sty {
        width: 50px;
      }
      & > div {
        display: inline-block;
        margin-top: 74px;
        position: relative;
        cursor: pointer;
        &:first-child:hover {
          .wx_code_content_sty {
            display: block;
            z-index: 999;
          }
        }
        &:nth-child(3):hover {
          .xcx_code_content_sty {
            display: block;
          }
        }
        & > i {
          color: #777777;
          font-size: 21px;
          //   &:first-child {
          //     margin-right: 50px;
          //   }
        }
        .xcx_code_content_sty,
        .wx_code_content_sty {
          position: absolute;
          width: 160px;
          height: 160px;
          background: #fff;
          top: -170px;
          box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
          left: -70px;
          display: none;
          &::after {
            position: absolute;
            content: "";
            width: 0;
            height: 0;
            border: 10px solid transparent;
            border-top-color: #fff;
            bottom: -20px;
            left: 50%;
            transform: translateX(-50%);
          }
          & > img {
            width: 160px;
            height: 160px;
          }
        }
      }
    }
  }
  .main_content_bottom_sty {
    padding-top: 10px;
    height: 67px;
    text-align: center;
    color: #777777;
    font-size: 12px;
    p,
    a {
      line-height: 2;
      transition: all 0.3s ease;
    }
    a:hover {
      text-decoration: underline;
    }
  }
}
</style>