<template>
  <div>
    <headNav />
    <router-view></router-view>
    <bottom />
  </div>
</template>

<script>
import headNav from "@/components/headNav";
import bottom from "@/components/bottomNav";
export default {
  components: {
    headNav,
    bottom,
  },
  data() {
    return {
      
    };
  },
  created() {

  },
};
</script>